import * as React from 'react';

const Privacy = () => (
    <div>
        <div className="content centered-headers">
            <div className="divider"/>
            <h1>Privacy Policy</h1>
            <p>Solar Grants Biotechnology Inc. (“SG Biotech” or “we”) is committed to managing its collection, use and
                disclosure of personal information in compliance with the Personal Information Protection and Electronic
                Documents Act (Canada) and other applicable federal and provincial laws governing privacy and the protection
                of personal data.</p>
            <h2>Personal Data</h2>
            <p>Personal data is information that can identify you as an individual, including your name, address, birth
                date, social insurance number and similar such information. This Policy describes how we will protect your
                personal data and your rights in relation to the information held by us, as well as the choices you can make
                about your personal data. This Policy applies regardless of how that information may be collected, whether
                face to face, over the phone, via a website, in any other interaction we might have with you, or from
                publicly available sources.</p>
            <h2>Consent to Collection of Personal Data</h2>
            <p>By using this website and by voluntarily submitting your personal data to us, you consent to the practices
                outlined in this Policy and any applicable terms of use. If you are submitting personal data of others, such
                as patients, you are responsible for obtaining their consent to the disclosure of their personal data to SG
                Biotech, as may be required by law.</p>
            <p>Your personal data may be consolidated, aggregated and rendered anonymous for internal research or reporting
                to governmental authorities purposes and disclosed to third parties as such (i.e. in an unidentifiable
                manner).</p>
            <h2>How Do We Collect Personal Data</h2>
            <p>Generally, we collect information only that you provide to us. If you do not want your personal data
                collected, please do not provide it to us, including in data fields on our website.</p>
            <p>SC Biotech may also collect personal data from public sources or visitor tracking (such as Google Analytics
                or Cookies), which may be combined with other information to result in personal data.</p>
            <p>Your data is stored through Shopify’s data storage, databases and the general Shopify application. They store
                your data on a secure server behind a firewall.</p>
            <h2>Children</h2>
            <p>This website is not intended for use by children. In light of the importance of protecting children's
                privacy, we do not collect, process or use on our website any information relating to an individual whom we
                know to be under 18 years old without the prior, verifiable consent of his or her legal representative. Such
                legal representative has the right, upon request, to view the information provided by the child and/or to
                require that it be deleted.</p>
            <h2>Use of Personal Data</h2>
            <p>We use a cookie to store your current cart to persist it though sessions.</p>
            <h2>Cookies</h2>
            <p>As is now common with most websites, our site uses cookies to record certain information about your use of
                the site. Cookies are small data files generated by a website and saved by your web browser. Cookies
                identify a user’s computer but they do not contain any personally identifiable information. Any information
                obtained consequently is used on an anonymous, aggregated basis and you cannot be identified from it. You
                are not required to accept a cookie that we send to you and you can modify your browser’s settings so that
                it will not accept cookies. If you choose to disable cookies, you can do so specifically for the type of
                browser you use.</p>
            <h2>Retention of Data</h2>
            <p>We only retain personal data for as long as is necessary for us to render a service you have requested or to
                which you have given your consent, except where otherwise provided by law (e.g. in connection with pending
                litigation).</p>
            <h2>Disclosure of your Personal Data</h2>
            <p>As a general rule, we do not share your personal data with third parties, unless it is necessary to conduct a
                joint program or to carry out a program or activity through a contractor. To carry out the legitimate
                business purposes as described in this Policy, we may disclose your personal information to affiliates and
                related companies, or to third party service providers (any of which may be located outside Canada) for
                purposes related to supporting our products, services and businesses be we will endeavor to ensure that our
                affiliates, related companies and service providers treat your personal data consistent with this
                Policy.</p>
            <h2>Changes to our Policy</h2>
            <p>We reserve the right to make changes to our Policy from time to time in order to reflect the latest legal
                requirements or to incorporate changes to our services in the data protection declaration.</p>
            <h2>Links</h2>
            <p>We are not responsible and should not be held to account or liable for links to other websites, other
                websites content or their data protection regulations.</p>
            <h2>Security</h2>
            <p>We maintain appropriate administrative, technical and physical safeguards and use appropriate commercially
                reasonable efforts to protect personal data against accidental or unlawful destruction, accidental loss,
                unauthorized alteration, unauthorized disclosure or access, misuse, and all other unlawful forms of
                processing of the Personal Information in our possession.</p>
            <h2>Contact</h2>
            <p>If you wish to know what information we have in our files about you, ask a question about the information in
                your file or request a change to the information in your file, please contact:</p>
        </div>
        <div className="content centered-text">
            <h2><a href="mailto:information@sgbiotec.com">information@sgbiotec.com</a></h2>
            <h2><a href="mailto:support@sgbiotec.com">support@sgbiotec.com</a></h2>
        </div>
        <div className="content centered-headers">
            Date
            Last updated: November 14, 2019

            <div className="divider"/>
            <div className="empty-space"></div>
        </div>
    </div>
);

export default Privacy;
