import * as React from 'react';
import Gallery from 'react-photo-gallery';

import plant from '../assets/plant-edited.png';
import leaves from '../assets/leaves-comparison.png';
import vial from '../assets/vial.jpg';

import img1 from '../assets/img1_annotated.png';
import img2 from '../assets/img2_annotated.png';
import img3 from '../assets/img3.png';
import img4 from '../assets/img4_annotated.png';

import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import Lightbox from 'react-image-lightbox';

import ProductDisplay from "./product_display";
import {useState} from "react";

const images = [
    {src: img1, width: 15, height: 11, caption: "eGFP gene is expressed in etioplasts / proplastids of leaf trichome cells of plastome-engineered tobacco."},
    {src: img2, width: 15, height: 11, caption: "Same with eBFP... "},
    {src: img3, width: 6, height: 10, caption: "SDS-PAGE resolution profile of crude leaf extracts from wild-type (WT) tobacco compared with a production bioreactor line."},
    {src: img4, width: 15, height: 11, caption: "Up to 4 grams of pure recombinant proteins obtained from 1 kg of fresh leaf bioreactor  biomass!"},
];

const Landing: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    return (
        <div>
            <div className="content">
                <div className="cta">
                    <h1>Fluorescent proteins made <span className="nowrap italics">in-planta</span></h1>
                    <h2>for molecular biology research</h2>
                    <ProductDisplay />
                </div>
            </div>
            <div className="divider" />
            <div className="features">
                <div className="content">
                    <div className="feature">
                        <div className="main-text">
                            <h2>We grow green bioreactors</h2>
                            <p>Solar Grants Biotechnology (SGB) Inc research is targeted on finding ways to harness the power of photosynthesis into recombinant protein production for human needs. Our focus is on turning photosynthesizing plants into capable, scalable, cost-effective “green biofactories” – production platforms for affordable recombinant proteins and other biologics.</p>
                        </div>
                        <div className="image right">
                            <img src={plant} alt="Plant" />
                        </div>
                    </div>

                    <div className="feature">
                        <div className="image left">
                            <img src={leaves} alt="Gene expression" />
                        </div>
                        <div className="main-text">
                            <h2>Optimized gene expression</h2>
                            <p>Our bioengineered tobacco plants allow tunable levels of recombinant protein expression and are capable of producing gram-amounts of various recombinant proteins in one plant.</p>
                        </div>
                    </div>

                    <div className="feature">
                        <div className="main-text">
                            <h2>High-quality research reagents</h2>
                            <p>SGB Inc is firmly committed to highest standards in quality of our recombinant proteins. Absence of animal or human-related pathogens is warranted by our plant-based production platform, while rigorous purification and filtering processes, followed by packaging in sterile conditions assure purity, homogeneity and stability of the products.</p>
                        </div>
                        <div className="image right">
                            <img src={vial} alt="Vial" />
                        </div>
                    </div>
                </div>
                <div className="empty-space" />
                <div className="divider" />
                <div className="content">
                    <h2 className="green">Our R&D in Cool Pictures</h2>
                    <Gallery photos={images} margin={10} onClick={(event, { photo, index }) => {
                        setPhotoIndex(index);
                        setIsOpen(true);
                    }}/>
                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex].src}
                            nextSrc={images[(photoIndex + 1) % images.length].src}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                        />
                    )}
                </div>
                <div className="empty-space" />
            </div>
        </div>
    );
};

export default Landing;
