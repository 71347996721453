import * as React from 'react';

const Contact = () => (
    <div className="content centered-text">
        <div className="divider"/>
        <h1>Contact us</h1>

        <h2>401-140 Oxford Street E, London, ON N6A 5R9</h2>

        <div className="empty-space"></div>

        <h2><a href="tel:15199025327">1-519-902-5327</a></h2>
        <h2><a href="tel:14163010403">1-416-301-0403</a></h2>

        <div className="empty-space"></div>

        <h2><a href="mailto:igor.k@sgbiotec.com">igor.k@sgbiotec.com</a></h2>
        <h2><a href="mailto:felipe.c@sgbiotec.com">felipe.c@sgbiotec.com</a></h2>
        <h2><a href="mailto:information@sgbiotec.com">information@sgbiotec.com</a></h2>
        <h2><a href="mailto:support@sgbiotec.com">support@sgbiotec.com</a></h2>
        <h2><a href="mailto:sales@sgbiotec.com">sales@sgbiotec.com</a></h2>

        <div className="divider"/>
        <div className="empty-space"></div>
    </div>
);

export default Contact;
