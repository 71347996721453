import {Link} from "react-router-dom";
import * as React from "react";

import gfpImage from '../assets/gfp_sm.png';
import bfpImage from '../assets/bfp_sm.png';
import rfpImage from '../assets/rfp_sm.png';
import yfpImage from '../assets/yfp_sm.png';

import * as routes from '../routes';

const ProductDisplay: React.FC = () => {
    return (
        <div className="products">
            <Link to={`${routes.PRODUCT}/${routes.GFP}`}>
                <img src={gfpImage} alt="GFP" />
                <h3><span className="green">G</span>FP</h3>
            </Link>
            <Link to={`${routes.PRODUCT}/${routes.YFP}`}>
                <img src={yfpImage} alt="Logo" />
                <h3><span className="yellow">Y</span>FP</h3>
            </Link>
            <Link to={`${routes.PRODUCT}/${routes.BFP}`}>
                <img src={bfpImage} alt="Logo" />
                <h3><span className="blue">B</span>FP</h3>
            </Link>
            <Link to={`${routes.PRODUCT}/${routes.RFP}`}>
                <img src={rfpImage} alt="Logo" />
                <h3><span className="red">R</span>FP</h3>
            </Link>
        </div>
    );
};

export default ProductDisplay;
