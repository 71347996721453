import * as React from 'react';

import img1 from '../assets/sun-leaf.png';
import img2 from '../assets/plant.jpg';
import img3 from '../assets/peatry_dish.jpg';

import igorImage from '../assets/portrait_igor.png';
import felipeImage from '../assets/portrait_felipe.png';
import linkedinImage from '../assets/linkedin.svg';
import leaves from "../assets/leaves-comparison.png";

const About = () => (
    <div className="content">
        <div className="divider" />
        <h1>Company’s Drive </h1>
        <br />
        <br />
        <p>Put sun energy into peptide bonds.</p>

        <p>Proteins are the building blocks of life. Recombinant proteins are widely and increasingly used in medicine,
            biotechnology and research. Advancements in large-scale production technologies are imperative to supply the
            growing demand. Current conventional processes for manufacturing of recombinant proteins use bioreactors, or
            fermenters, to cultivate bacterial, insect or mammalian cells. Bioreactors are generally costly to build,
            and energy-inefficient in operation due to the use of highly-processed input materials. They also have
            limited scalability, often rendering the product prohibitively expensive. Let the plants do their work
            instead.</p>

        <p>Photosynthesis empowers all life on our planet. This truly "green technology", functional for more than 3
            billion years, incorporates and stores the energy of sunlight into food, using water and carbon dioxide as
            the raw input materials and generating oxygen as a by-product. Biosynthetic process “plugged” into the Sun.
            In photosynthetic organisms solar energy absorbed by the cells drives biosynthesis and production of
            virtually every cellular component. Natural similarity of the cellular components and basic biological
            processes in all living organisms offers the opportunity for employing the molecular machinery for protein
            synthesis in photosynthetic plant cells as a bioreactor. It makes sense to tap this solar-powered
            biosynthetic process to produce recombinant proteins for human needs, making the manufacturing eco-friendly
            and the products more affordable.</p>

        <p>Solar Grants Biotechnology Inc. (SGB Inc) is a young Canadian biotechnology company focused on harnessing
            the power of photosynthesis into the production of recombinant proteins. The company develops carefully
            crafted, state-of-the-art engineered tobacco plant lines that express and accumulate various recombinant
            proteins and peptides. The process of production is compellingly cost-effective, when compared with the
            conventional, fermenter bioreactor-based technologies. Developed by SGB Inc, the fine-tuned, photosynthetic
            “bioreactor tobacco plants” are capable of producing gram-amounts of recombinant proteins in one plant,
            while reducing carbon dioxide amount in the air and requiring minimal up-front investment - the
            characteristics of a remarkably environmentally-friendly, energy-efficient and cost-effective
            bioprocess!</p>

        <div className="empty-space" />
        <div className="divider" />
        <div className="empty-space" />

        {/*<h2>Photosynthesis</h2>*/}
        {/*<p>Energy meant mass for plants long before Albert Einstein formulated this principal in his famous elegant*/}
        {/*    equasion, plants use sun energy and create biomass. Plants “run on solar”. Look at that leaf. A layer of living*/}
        {/*    cells stretching toward the sunlight. These cells contain chloroplasts, a distinctive feature of a plant cell, the*/}
        {/*    very sites of photosynthesis. These truly marvelous organelles energize almost all food chains on our planet.*/}
        {/*    Chloroplasts maintain intense protein synthesis and produce the most abundant protein on Earth – the large RUBISCO*/}
        {/*    subunit. Realize the potential. Tapping this sustainable resourse for recombinant protein synthesis makes sense in*/}
        {/*    many ways.</p>*/}

        <div className="feature">
            <div className="image left">
                <img src={img1} alt="Photosynthesis" />
            </div>
            <div className="main-text">
                <h2>Photosynthesis</h2>

                <p>Energy meant mass for plants long before Albert Einstein formulated this principal in his famous elegant
                    equation, plants use sun energy and create biomass. Plants “run on solar”. Look at that leaf. A layer of living
                    cells stretching toward the sunlight. These cells contain chloroplasts, a distinctive feature of a plant cell, the
                    very sites of photosynthesis. These truly marvelous organelles energize almost all food chains on our planet.
                    Chloroplasts maintain intense protein synthesis and produce the most abundant protein on Earth – the large RUBISCO
                    subunit. Realize the potential. Tapping this sustainable resourse for recombinant protein synthesis makes sense in
                    many ways.</p>
            </div>
        </div>

        <div className="feature">
            <div className="main-text">
                <h2>Plants as Bioreactors</h2>

                <p>Mechanisms of protein synthesis are shared among kingdoms of life, thus, it is desirable to explore ways to
                    harness the power of plants as a production platform / bioreactor for recombinant proteins. Plants embody most
                    cost-effective, energy-efficient bioreactor available, operating with a negative carbon dioxide footprint. At SGB
                    Inc we craft engineered plant bioreactors that produce various recombinant proteins.</p>
            </div>
            <div className="image right">
                <img src={img2} alt="Plant" />
                {/*FIXME*/}
            </div>
        </div>

        <div className="feature">
            <div className="image left">
                <img src={img3} alt="Photosynthesis" />
            </div>
            <div className="main-text">
                <h2>Benefits of Molecular Pharming</h2>

                <p>Generation of biomass that synthesizes and accumulates the recombinant protein of choice (“upstream” production)
                    becomes an easy task of plants cultivation.</p>

                <p>Uncapped potential for production scale-up without the need for significant up-front investments.</p>

                <p>Higher safety due to inability of human or animal pathogens to propagate in plant tissue.</p>

                <p>Reduced environmental pollution footprint, compared with the conventional, bioreactor-based processes.</p>
            </div>
        </div>

        <div className="empty-space" />
        <div className="divider" />
        <div className="empty-space" />

        <h1>Our Team</h1>

        <div className="feature">
            <div className="image left">
                <img src={igorImage} alt="Igor Kolotilin" />
            </div>
            <div className="main-text small-text">
                <h2 className="linkedin-title"><span>Igor Kolotilin, Ph.D.</span> <a href="https://www.linkedin.com/in/igor-kolotilin-652a2616" target="_blank"><img src={linkedinImage} alt="LinkedIn Profile" /></a></h2>
                <h3>CSO & President</h3>
                <p>Dr. Igor Kolotilin is an expert in plant biotechnology, received his Ph.D. from the Hebrew University in
                    Jerusalem and previously held positions as an NSERC Visiting fellow in Canadian governmental laboratories
                    (Agriculture and Agri-Food Canada), as well as a Visiting Scientist in the Department of Biology at Western
                    University. His research is focused on novel applications for the biosynthetic power of plant cells and
                    plastids and aimed to serve the growing need of humans for affordable vaccines and biopharmaceuticals.</p>
                <p>Dr. Igor Kolotilin is the founder, Chief Scientific Officer and President of Solar Grants Biotechnology Inc. -
                    a start-up company developing plant-based platforms for production of various recombinant proteins and other
                    biologics.</p>
            </div>
        </div>

        <div className="feature">
            <div className="image left">
                <img src={felipeImage} alt="Felipe Campusano" />
            </div>
            <div className="main-text small-text">
                <h2 className="linkedin-title"><span>Felipe Campusano</span> <a href="https://www.linkedin.com/in/felipe-campusano-b25b60128" target="_blank"><img src={linkedinImage} alt="LinkedIn Profile" /></a></h2>
                <h3>CEO</h3>
                <p>Pharmacist, entrepreneur, businessman. Felipe Campusano has both purchased and started number of businesses
                    predominately within the
                    health care sector. Graduating in 2006 from the University of Toronto’s Faculty of Pharmacy, he acquired
                    his first pharmacy the day he received his license, one year later he bought his second. Fast forward to
                    today and Felipe has a significant ownership interest in over 50 pharmacies across Canada. In 2015 he
                    founded Liver Care Canada and as CEO lead its growth to 17 clinics across Ontario with revenues over $90
                    million.</p>

                <p>Felipe Campusano currently sits as Liver Care Canada’s Chairman of the board. He is a serial entrepreneur and
                    continues to grow his portfolio of businesses. He brings leadership focused on problem solving and teamwork
                    and has committed to guiding Solar Grants Biotechnology Inc. as the company introduces itself to the
                    world.</p>
            </div>
        </div>
        <div className="empty-space" />
    </div>
);

export default About;
