// import {observable, computed} from 'mobx';
import { makeObservable, observable, computed, action, flow } from "mobx"
import Client from "shopify-buy";
import {Cookies} from "react-cookie";

// custom
export interface Specs {
    title: string,
    summary: string[][],
    sequence: string,
    specifications: string[][],
    instructions: string[][],
}

export interface Cart {
    id: string,
    lineItems: LineItem[],
    subtotalPrice: Price,
    webUrl: string,
}

export interface LineItem {
    id: string,
    title: string,
    quantity: number,
    variant: ProductVariant,
}

export interface ProductVariant {
    id: string,
    title: string,
    image: Image,
    price: Price,
    available: boolean,
}

export interface Price {
    amount: string,
    currencyCode: string,
}

export interface Product {
    title: string,
    description: string,
    variants: ProductVariant[],
    images: Image[],
}

export interface Image {
    id: string,
    altText: string,
    src: string,
}

class Shopify {
    client = Client.buildClient({
        storefrontAccessToken: process.env.STORE_FRONT_ACCESS_TOKEN || "",
        domain: process.env.STORE_FRONT_DOMAIN || "",
    });

    //-----------------------------------------------------

    // @observable productMap: Map<string, Product> = null;

    // @observable productId: string = null;

    /*@observable*/ checkout: Cart = null;

    // @observable specsMap: Map<string, Specs> = null;

    /*@observable*/ inProgress: boolean = false;

    /*@observable*/ message: string = null;

    //-----------------------------------------------------

    /*@computed*/ get cartCount(): number {
        if (!this.checkout) {
            return null;
        }
        return this.checkout.lineItems.reduce((accum: number, item: LineItem) => accum + item.quantity, 0);
    }

    //-----------------------------------------------------

    constructor() {
        // https://mobx.js.org/observable-state.html
        makeObservable(this, {
            checkout: observable,
            inProgress: observable,
            message: observable,
            cartCount: computed,
            initCart: action,
            setMessage: action,
            addToCart: action,
            removeFromCart: action,
            setCartQuantity: action,
            changeCartQuantity: action,
            // fetch: flow
        })

        this.initCart();
    }

    async initCart() {
        console.log("init cart");
        const cartCookie = new Cookies().get("shopifycartid");
        if (cartCookie) {
            this.checkout = await this.client.checkout.fetch(cartCookie);
        }
        else {
            this.checkout = await this.client.checkout.create();
        }
        new Cookies().set("shopifycartid", this.checkout.id.toString(), { sameSite: "strict", path: "/" }); /*TODO: 1) SameSite attr, 2) path: "/" <- "/product" */
    }

    setMessage(msg: string) {
        this.message = msg;
        setTimeout(() => {
            if (this.message === msg) {
                this.message = null;
            }
        }, 3000);
    }

    async addToCart(variantId: string, quantity: number) {
        console.log("ADD TO: " + this.checkout.id);
        this.inProgress = true;
        this.setMessage("processing");

        const lineItemsToAdd: any = [{variantId: variantId, quantity: quantity}];
        this.checkout = await this.client.checkout.addLineItems(this.checkout.id, lineItemsToAdd);
        
        console.log(this.checkout);
        this.inProgress = false;
        this.setMessage("Added item to the cart");
    }

    async removeFromCart(variantId: string) {
        console.log("REMOVE: " + this.checkout.id);
        this.inProgress = true;
        this.setMessage("processing");

        const itemsToRemove: any = [variantId];
        this.checkout = await this.client.checkout.removeLineItems(this.checkout.id, itemsToRemove);

        console.log(this.checkout);
        this.inProgress = false;
        this.setMessage("Removed item from the cart");
    }

    async setCartQuantity(variantId: string, quantity: number) {
        console.log(variantId); // gid://shopify/CheckoutLineItem/292378416579040?checkout=f7ec399cb564d3e48e9f6eb9daf7eb48
        console.log(this.checkout.lineItems);




        console.log("QUANTITY: " + this.checkout.id);
        this.inProgress = true;
        this.setMessage("processing");

        const lineItem = this.checkout.lineItems.find((item) => item.id === variantId);
        if (!lineItem) {
            console.log("line item not found: " + variantId);
            return;
        }
        const lineItemsToChange: any = [{id: variantId, quantity: quantity}];
        // console.log(this.client.checkout);
        this.checkout = await this.client.checkout.updateLineItems(this.checkout.id, lineItemsToChange);

        console.log(this.checkout);
        this.inProgress = false;
        this.setMessage("Changed item quantity");
    }

    async changeCartQuantity(variantId: string, delta: -1|1) {
        console.log("CHANGE: " + this.checkout.id);
        
        const lineItem = this.checkout.lineItems.find((item) => item.id === variantId);
        if (!lineItem) {
            console.log("line item not found: " + variantId);
            return;
        }
        if (delta === -1 && lineItem.quantity === 1) {
            this.removeFromCart(variantId);
        }
        else {
            this.setCartQuantity(variantId, lineItem.quantity + delta);
        }
    }
}

const shopify = new Shopify();

export default shopify;
