import * as React from 'react';
import {observer} from 'mobx-react';
import {Button} from "antd";
import InputNumber from "antd/lib/input-number";

import shopify from "../shopify";
import ProductDisplay from './product_display';

const Cart: React.FC = () => {
    if (!shopify.checkout) {
        return <div>not ready yet</div>;
    }

    const isEmpty = shopify.checkout && shopify.checkout.lineItems.length === 0;
    if (isEmpty) {
        return (
            <div className="content">
                <h1>Your cart is empty</h1>
                <h2>Shop our fluorescent proteins made <span className="nowrap italics">in-planta</span></h2>
                <ProductDisplay />
                <div className="empty-space" />
            </div>
        );
    }

    return (
        <div className="content">
            <div className="divider" />
            <h1>Cart</h1>
            <div className="cart">
                {shopify.checkout && shopify.checkout.lineItems.map((item) => [
                    <div className="cart-image" key={`${item.id}-image`}>
                        <img src={item.variant.image.src} style={{height: "100px", width: "auto"}} alt="img" />
                    </div>,
                    <div className="description" key={`${item.id}-description`}>
                        <h3>{item.title}</h3>
                        <h5>{item.variant.title} &nbsp; <button type="button" onClick={() => shopify.removeFromCart(item.id.toString())}>remove</button></h5>
                    </div>,
                    <div style={{ paddingTop: "10px" }}  key={`${item.id}-quantity`}> { /* TODO keep or remove */ }
                        <InputNumber key={`${item.id}-quantity`} size="large" min={1} max={10} value={item.quantity} onChange={(value) => shopify.setCartQuantity(item.id.toString(), value)} />
                    </div>,
                    <h2 className="price padding" key={`${item.id}-price`}>${item.variant.price.amount} CAD</h2>,
                ])}
                <div></div>
                <div></div>
                <div></div>
                <div className="price padding">
                    <h5>Subtotal:</h5>
                    <h2 className="price">${shopify.checkout && shopify.checkout.subtotalPrice.amount} CAD</h2>
                    <h6>Shipping calculated at checkout</h6>

                    <a href={shopify.checkout ? shopify.checkout.webUrl : ""} target="checkout" style={{textDecoration: "none"}}>
                        <Button className="checkout" type="primary" size="large" onClick={() => {}} disabled={shopify.inProgress}>Checkout</Button>
                    </a>
                    <span className="message">{shopify.message}</span>
                </div>
            </div>
            <br />
            <div className="empty-space" />
        </div>
    );
};

export default observer(Cart);
