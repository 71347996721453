import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Routes,
    Link
} from "react-router-dom";


// Lightbox styles
// import 'react-image-lightbox/style.css';

import * as routes from './routes';
import About from "./components/about";
import ProductPage from "./components/product_page";
import Menu from "./components/menu";
import Landing from "./components/landing";
import Cart from "./components/cart";

import logoLeaves from './assets/leaves.png';
import "./styles.scss";
import Contact from "./components/contact";
import Privacy from "./components/privacy";

const App: React.FC = () => {
    return (
        <RouterProvider router={router} />
    );
};

const router = createBrowserRouter([
    { path: "*", Component: Root },
]);

function Root() {
    return (
        <>
            <Menu />
            <Routes>
                <Route path={routes.LANDING} element={<Landing/>} />
                <Route path={routes.ABOUT} element={<About/>} />
                <Route path={`${routes.PRODUCT}/:productLineId`} element={<ProductPage/>} />
                <Route path={routes.CART} element={<Cart/>} />
                <Route path={routes.CONTACT} element={<Contact/>} />
                <Route path={routes.PRIVACY} element={<Privacy/>} />
            </Routes>
            <footer>
                <div className="content">
                    <div className="contact-info">
                        <span className="very-large-only">401-140 Oxford street east, London, ON N6A 5R9</span>
                        <span className="very-large-only bar">|</span>
                        <span className="large-only"><a href="tel:15199025327">1-519-902-5327</a></span>
                        <span className="large-only bar">|</span>
                        <span className="large-only"><a href="tel:14163010403">1-416-301-0403</a></span>
                        <span className="large-only bar">|</span>
                        <span><Link to={routes.CONTACT}>contact</Link></span>
                    </div>
                    <div className="flex">
                        <img src={logoLeaves} alt="Logo" />
                        <div>
                            <h2>Solar Grants Biotechnology Inc.</h2>
                            <p>Solar Grants Biotechnology (SGB) Inc.'s research is targeted on finding ways to harness the power of photosynthesis into recombinant protein production for human needs. Building on similarities of basic biological processes in all living cells, our focus is on turning photosynthesizing plants into capable, scalable, cost-effective “green biofactories” – production platforms for affordable recombinant proteins and other biologics.</p>
                        </div>
                    </div>
                    <h6>
                        Copyright © 2020 Solar Grants Biotechnology Inc.
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="large-only bar">|</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to={routes.PRIVACY}>Privacy Policy</Link>
                    </h6>
                </div>
            </footer>
        </>
    );
}

const mountNode = document.getElementById("app");
ReactDOM.render(<App />, mountNode);
