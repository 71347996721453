import * as React from 'react';
import {Affix} from "antd";
import {Link} from "react-router-dom";
import {observer} from 'mobx-react';

import * as routes from '../routes';
import shopify from '../shopify';


import logoImage from '../assets/SG_logo_screen_sm.png';
import cartImage from '../assets/shopping_cart_icon.png';

const Menu: React.FC = () => {
    const [affixed, setAffixed] = React.useState(false);

    return (
        <Affix offsetTop={0} onChange={(affix: any) => setAffixed(affix ? affix : false)}>
            {/*FALSE IS AT TOP!*/}
            <div className={"header" + (affixed ? " affixed" : "")}>
                <Link className="logo" to={routes.LANDING} onClick={() => window.scrollTo(0, 0)}>
                    <img src={logoImage} alt="Logo" />
                </Link>
                <div className="buttons">
                    <div className="only-large-screen">
                        <Link to={`${routes.PRODUCT}/${routes.GFP}`}>
                            GFP
                        </Link>
                        <Link to={`${routes.PRODUCT}/${routes.YFP}`}>
                            YFP
                        </Link>
                        <Link to={`${routes.PRODUCT}/${routes.BFP}`}>
                            BFP
                        </Link>
                        <Link to={`${routes.PRODUCT}/${routes.RFP}`}>
                            RFP
                        </Link>
                    </div>
                    <Link to={routes.ABOUT}>
                        {/*our&nbsp;company*/}
                        company
                    </Link>
                    <Link to={routes.CONTACT}>
                        contact
                    </Link>
                </div>
                <Link className="cart-icon" to={routes.CART} style={{ textAlign: "center" }}>
                    <img src={cartImage} alt="Cart" className={(shopify.inProgress) ? "in-progress" : ""} />&nbsp;<span id="cart-count">{shopify.cartCount}</span>
                </Link>
            </div>
        </Affix>
    );
};

export default observer(Menu);
