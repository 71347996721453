// URL route constants.
// Are used in Link 'to', Route 'path', and history 'push'.

export const LANDING = '/';
export const ABOUT = '/about';
export const PRODUCT = '/product';
export const CART = '/cart';
export const CONTACT = '/contact';
export const PRIVACY = '/privacy-policy';

export const GFP = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzM3NTE1OTk0MDcxNTI=";
export const BFP = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzM4MzU5MjIzMTczNjA=";
export const YFP = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzM4MzU5MjI3NzYxMTI=";
export const RFP = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzM4MzU5MjMxMDM3OTI=";
