import * as React from 'react';
import {Button, InputNumber, Select} from "antd";
import Magnifier from "react-magnifier";
import {observer} from 'mobx-react';
import {useParams} from "react-router-dom";

import shopify from "../shopify";
import type {Product, ProductVariant, Image, Specs} from "../shopify";
import ProductDisplay from "./product_display";
import downloadImage from '../assets/download.png';



function scrollToProductSpecs() {
    document.querySelector('.product-specs').scrollIntoView({ behavior: 'smooth' });
}

function firstAvailableVariant(product: Product) : ProductVariant|null {
    const availableVariants = product.variants.filter((vrt) => vrt.available);
    if (availableVariants.length === 0) {
        return null;
    }
    return availableVariants[0];
}

function ProductPage() {
    let { productLineId } = useParams();

    const [product, setProduct] = React.useState<Product>(null);
    const [specs, setSpecs] = React.useState<Specs>(null);
    const [selectedVariant, setSelectedVariant] = React.useState<ProductVariant | null>(null);
    const [quantity, setQuantity] = React.useState<number>(1);
    const [selectedImage, setSelectedImage] = React.useState<Image | null>(null);

    React.useEffect(() => {
        const fetchProduct = async (id: string) => {
            const json = await shopify.client.product.fetch(id);
            setProduct(json);
            setSelectedVariant(firstAvailableVariant(json));
            setSelectedImage(json.images[0]);
        }

        const fetchSpecs = async (id: string) => {
            console.log("init spec: " + id);
            const response = await fetch("/specs/" + id + ".json");
            const json = await response.json();
            setSpecs(json);
        }

        fetchProduct(productLineId).catch(console.error);
        fetchSpecs(productLineId).catch(console.error);
    }, [productLineId]);

    if (!product || !specs) {
        return <div>loading...</div>;
    }

    const zoomImgSrc = selectedImage ? selectedImage.src : "";

    return (
        <div>
            <div className="features">
                <div className="content">
                    <div className="product-info">
                        <div className="images">
                            <div className="other-images">
                                {product.images
                                    .map((img) => ([
                                        <div key={`${img.id}-alt-text`}>{img.altText}</div>,
                                        <img key={img.id} src={img.src} className={(img === selectedImage) ? "selected-image" : ""} alt="thumbnail" onClick={() => setSelectedImage(img)} />,
                                    ]))}
                            </div>
                            <div className="selected-image" style={{ margin: "auto 0" }}>
                                {/*<img src={zoomImgSrc} width={200} height={200}/>*/}
                                <Magnifier src={zoomImgSrc} mgWidth={200} mgHeight={200} />
                            </div>
                        </div>
                        <div className="info">
                            <h2>{product.title}</h2>
                            <h2 className="price">${selectedVariant && selectedVariant.price.amount} CAD</h2>
                            <p>{product.description}</p>

                            {specs.summary.map((sp) => (
                                <div key={sp[0]}>
                                    <h5>{sp[0]}</h5>
                                    <p>{sp[1]}</p>
                                </div>
                            ))}

                            <Select
                                disabled={!selectedVariant}
                                className="variant-select" 
                                size="large" 
                                value={selectedVariant && selectedVariant.title}
                                style={{width: 120}}
                                onChange={(id: string) => setSelectedVariant(product.variants.filter((el) => el.id === id)[0])}>
                                {product.variants.map((variant: ProductVariant) => (
                                    <Select.Option value={variant.id} key={variant.id} disabled={!variant.available}>{variant.title}</Select.Option>
                                ))}
                            </Select>

                            { /* <h5>Quantity</h5> */ }
                            <InputNumber disabled={!selectedVariant} size="large" min={1} max={10} value={quantity} onChange={(value) => setQuantity(value ? value : 1)} />

                            {!selectedVariant && <h5>None available in stock.</h5>}

                            <Button className="add-to-cart" type="primary" size="large" disabled={shopify.inProgress || !selectedVariant} onClick={() => shopify.addToCart(selectedVariant.id.toString(), quantity)}>
                                Add to cart
                            </Button>
                            <span className="message">{shopify.message}</span>
                            <br />&nbsp;
                        </div>
                    </div>
                </div>
                <div className="scroll-to-bottom" onClick={scrollToProductSpecs} role="button" tabIndex={0}>
                    <span>
                        <img src="https://www.iconpacks.net/icons/2/free-arrow-down-icon-3101-thumb.png" style={{"margin": "10px"}} />
                    </span>
                </div>
                {/*<Divider><Icon type="down"/></Divider>*/}
            </div>
            <div className="product-specs">
                <div className="content">
                    <div className="empty-space"></div>
                    <div className="divider" />
                    <div className="empty-space"></div>

                    <h2 className="linkedin-title"><span>Specifications</span> <a href={`../specs/docs/${specs.title}.pdf`} target="_blank" download rel="noopener noreferrer"><img src={downloadImage} alt="Download report" /></a></h2>

                    <h3>Molecular parameters</h3>
                    <h5>Amino Acids Sequence</h5>
                    <p>{specs.sequence}</p>

                    <div className="specs">
                        {specs.specifications.map((sp) => (
                            <div key={sp[0]}>
                                <h5>{sp[0]}</h5>
                                <p>{sp[1]}</p>
                            </div>
                        ))}
                    </div>

                    {specs.instructions.map((sp) => (
                        <div key={sp[0]}>
                            <h3>{sp[0]}</h3>
                            <p>{sp[1]}</p>
                        </div>
                    ))}

                    <div key="Testimonials">
                        <h3 className="linkedin-title"><span>Testimonials</span> <a href={`../specs/docs/testimonials.pdf`} target="_blank" download rel="noopener noreferrer"><img src={downloadImage} alt="Download testimonials" /></a></h3>
                        <p>Shared user experiences with our products</p>
                    </div>

                    <br/>
                    <br/>
                    <div key="Important note">
                        <h3>Important note</h3>
                        <p>All SGB Inc products are <b>FOR RESEARCH USE ONLY AND ARE NOT INTENDED FOR DIAGNOSTIC OR THERAPEUTIC USE.</b></p>
                    </div>
            </div>
            </div>
            <div className="content">
                <div className="empty-space" />
                <div className="divider" />
                <div className="empty-space" />
                <h2>Our other fluorescent proteins made <span className="nowrap italics">in-planta</span></h2>
                <ProductDisplay />
                <div className="empty-space" />
            </div>
        </div>
    );
};

export default observer(ProductPage);
